const blogsData = {
  id: "blogs",
  section: "blogs",
  section_title: {
    title: "Andovar Thought <span>Leadership</span>",
    subtitle: "RESOURCES",
  },
  buttons: [
    {
      id: 1,
      path: "https://blog.andovar.com",
      color: "primary",
      iconposition: "right",
      icondistance: "8px",
      iconsize: "24px",
      content: "View All Blogs",
    },
  ],
  tags: [
    {
      id: 1,
      content: "Apps",
    },
    {
      id: 2,
      content: "Games",
    },
    {
      id: 3,
      content: "Apps Trends",
    },
    {
      id: 4,
      content: "Virtual Trend",
    },
    {
      id: 4,
      content: "Strategy Software",
    },
  ],
};

export default blogsData;
