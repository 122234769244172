import agoda from "../data/images/case-studies/agoda.webp";
import spotify from "../data/images/case-studies/spotify.webp";
import airbnb from "../data/images/case-studies/airbnb.webp";

const caseStudiesData = {
  id: "caseStudy",
  section: "case-study",
  section_title: {
    title: "Success <span>Stories</span>",
    subtitle: "Case Studies",
  },
  buttons: [
    {
      id: 1,
      path: "/our-work",
      color: "primary",
      iconposition: "right",
      icondistance: "8px",
      iconsize: "24px",
      content: "View All Stories",
    },
  ],
  items: [
    {
      id: 1,
      images: [
        {
          src: agoda,
        },
      ],
      title: "Agoda",
      description:
        "Audiences across Europe and Asia can now access Agoda’s extensive travel services in their own languages.",
      path: "/our-work/agoda",
    },
    {
      id: 2,
      images: [
        {
          src: spotify,
        },
      ],
      title: "Spotify",
      description:
        "Reaching out to music lovers in Thailand, Spotify translated its user interface into Thai, expanding into a new market.",
      path: "/our-work/spotify",
    },
    {
      id: 3,
      images: [
        {
          src: airbnb,
        },
      ],
      title: "Airbnb",
      description:
        "Preparing to launch in a new region, Airbnb wanted to adapt its marketing content and localize its app and videos.",
      path: "/our-work/airbnb",
    },
  ],
};

export default caseStudiesData;
