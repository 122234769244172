import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import PropTypes from "prop-types";
import Image from "@ui/image";
import Heading from "@ui/heading";
import SectionTitle from "@ui/section-title"
import {
  LanguageExpertiseWrapper,
  LanguageExpertiseInner,
  LanguageExpertiseBox,
  LanguageExpertiseIconwarp,
  LanguageExpertiseTextwarp,
} from "./style";
// const Image = React.lazy(() => import('@ui/image'));
// const Heading = React.lazy(() => import('@ui/heading'));
// const SectionTitle = React.lazy(() => import('@ui/section-title'));

export default function LanguageExpertise({ headingStyle, data }) {
  return (
    typeof window !== 'undefined' && (
      <React.Suspense fallback={<div></div>}>
    <LanguageExpertiseWrapper>
      <LanguageExpertiseInner>
        <Container>
          <Row>
            <Col lg={12}>
              <SectionTitle className="introText"
                subtitle={data.section_title.subtitle}
                title={data.section_title.title}
                description={data.section_title.description}
              />
            </Col>
          </Row>

          <Row>
            {data.items.map((data, index) => (
              <Col lg={4} className="mb-30" key={index}>
                <LanguageExpertiseBox>
                  <LanguageExpertiseIconwarp>
                    <Image src={data.images[0].src} alt="automated" />
                  </LanguageExpertiseIconwarp>
                  <LanguageExpertiseTextwarp>
                    <Heading {...headingStyle}>{data.title}</Heading>
                  </LanguageExpertiseTextwarp>
                </LanguageExpertiseBox>
              </Col>
            ))}
          </Row>
        </Container>
      </LanguageExpertiseInner>
    </LanguageExpertiseWrapper>
    </React.Suspense>
)
  );
}
LanguageExpertise.propTypes = {
  headingStyle: PropTypes.object,
};

LanguageExpertise.defaultProps = {
  headingStyle: {
    as: "h4",
    color: "#000",
    fontSize: "22px",
    fontweight: 700,
    m: 0,
  },
};
