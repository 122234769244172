import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import PropTypes from "prop-types";
import {
  HowweworkWrapper,
  HowweworkInner,
  WorkStepWrap,
  WorkStepWrapInner,
  WorkStepiconWrap,
  WorkStepicon,
  WorkStepTextWrap,
} from "./style";
const Heading = React.lazy(() => import('@ui/heading'));
const Text = React.lazy(() => import('@ui/text'));
const SectionTitle = React.lazy(() => import('@ui/section-title'));

const HowWeWork = React.memo(({
  SectionTitleStyle,
  HeadingStyle,
  TextStyle,
  data,
  layout,
  ...props
}) =>{
  return (
    typeof window !== 'undefined' && (
      <React.Suspense fallback={<div></div>}>
    <HowweworkWrapper layout={layout} {...props}>
      <HowweworkInner>
        <Container>
          <Row>
            <Col>
              <SectionTitle
                title={data.section_title?.title}
                subtitle={data.section_title?.subtitle}
              />
            </Col>
          </Row>

          <Row>
            <Col>
              <WorkStepWrap>
                {data &&
                  data.items.map((el, index) => (
                    <WorkStepWrapInner key={index}>
                      <WorkStepiconWrap>
                        <WorkStepicon>{el.id}</WorkStepicon>
                      </WorkStepiconWrap>
                      <WorkStepTextWrap>
                        <Heading {...HeadingStyle}>{el.title}</Heading>
                        <Text {...TextStyle}>{el.description}</Text>
                      </WorkStepTextWrap>
                    </WorkStepWrapInner>
                  ))}
              </WorkStepWrap>
            </Col>
          </Row>
        </Container>
      </HowweworkInner>
    </HowweworkWrapper>
     </React.Suspense>
 )
  );
})

HowWeWork.propTypes = {
  SectionTitleStyle: PropTypes.object,
  HeadingStyle: PropTypes.object,
  TextStyle: PropTypes.object,
  layout: PropTypes.oneOf([1, 2, 3, 4]),
};
HowWeWork.defaultProps = {
  layout: 1,
  SectionTitleStyle: {
  },
  HeadingStyle: {
    as: "h5",
    color: "#fff",
    fontSize: "22px",
    fontweight: "bold",
    mb: "24px",
  },
  TextStyle: {
    color: "#fff",
  },
};
export default HowWeWork;