import styled, { themeGet, device } from "@styled";

export const LanguageExpertiseWrapper = styled.section`
  padding: 50px 0;

  ${device.medium} {
    padding: 80px 0;
  }

  ${device.large} {
    padding: 100px 0;
  }

`;
export const LanguageExpertiseInner = styled.div`
  .introText p {
    font-size: 17px;
  }

  .mb-30 {
    margin-bottom: 30px;
  }
`;
export const LanguageExpertiseBox = styled.div`
  display: flex;
  align-items: center;
  border-radius: 6px;
  border: 1px solid #e6e6e6;
  padding: 15px 10px;
  margin-bottom: 30px;
  height: 100%;

  @media (max-width: 991px) {
    height: unset;
  }
`;
export const LanguageExpertiseIconwarp = styled.div`
  min-width: 80px;
  width: 80px;
  height: 80px;
  margin-right: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  background: linear-gradient(
    320deg,
    ${themeGet("colors.secondaryDark")},
    ${themeGet("colors.secondary")}
  );
`;
export const LanguageExpertiseTextwarp = styled.div`
    h4 {
      font-size: 17px;
    }
`;
