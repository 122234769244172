import React from 'react'
import PropTypes from 'prop-types'
import { Container, Row, Col } from 'react-bootstrap'
import SectionTitle from '@ui/section-title'
import { Link } from 'gatsby'
import Heading from '@ui/heading'
import Text from '@ui/text'
import Button from '@ui/button'
import Anchor from '@ui/anchor'
import Image from '@ui/image'

import {
  CaseStudyWrap,
  CasestudyContentwrap,
  ImageWrap,
  CaseStudyTextWrap,
  ButtonWrapTop,
  ButtonWrap
} from './style'

const CaseStudy = ({
  HeadingStyle,
  data,
  dataSinglePage,
  layout,
  ...props
}) => {
  return (
    <CaseStudyWrap layout={layout} {...props} id={data.section}>
      <Container>
        <Row className='align-items-center'>
          <Col lg={8}>
            <SectionTitle
              title={
                dataSinglePage?.section_title
                  ? dataSinglePage.section_title.title
                  : data.section_title?.title
              }
              subtitle={
                dataSinglePage?.section_title
                  ? dataSinglePage.section_title.subtitle
                  : data.section_title?.subtitle
              }
              description={
                dataSinglePage?.section_title
                  ? dataSinglePage.section_title.description
                  : data.section_title?.description
              }
            />
          </Col>
          <Col lg={4}>
            <ButtonWrapTop>
              {data?.buttons?.map(({ id, content, ...rest }) => (
                <Button key={id} m='7px' {...rest}>
                  {content}
                </Button>
              ))}
            </ButtonWrapTop>
          </Col>
        </Row>

        <Row>
          {data &&
            data.items.map((el, index) => (
              <Col lg={4} key={index}>
                <CasestudyContentwrap>
                  <ImageWrap>
                    <Image
                      src={el.images[0].src}
                      alt={el.images[0]?.alt || 'Icon'}
                    />
                  </ImageWrap>
                  <CaseStudyTextWrap>
                    <Heading as='h6'>{el.title}</Heading>
                    <Text>{el.description}</Text>
                    <ButtonWrap>
                      <Anchor as={Link} to={el.path} key={index}>
                        READ MORE
                      </Anchor>
                    </ButtonWrap>
                  </CaseStudyTextWrap>
                </CasestudyContentwrap>
              </Col>
            ))}
        </Row>
        {/* <Row>
          <Col>
            <div className='reviewsScriptWrapper'>
              <div
                className='reviewsScriptWrapper-inner'
                dangerouslySetInnerHTML={{
                  __html: `<script defer async> (function () { var on = window.addEventListener ? function (m,c) { window.addEventListener(m,c,false); } : function (m,c) { window.attachEvent('on'+m,c); } , r = function (e) { if (e.origin !== "https://my.g2.com") { return; } var d = document.getElementById('g2-crowd-widget-testimonial-1074602'); if(d){d.style.height = e.data}; r = function () {}; } ; on('message', r); }()); </script><iframe title="reviewSectionFrame" name="reviewSectionFrame" frameborder="0" height="100%" id="g2-crowd-widget-testimonial-1074602" src="https://www.g2.com/products/andovar/testimonials/1074602.embed" style="width:100%;min-height:100%;overflow:hidden;" width="100%"  loading="lazy" ></iframe><a style="font-size: 12px; color: #446273; float: right;" aria-label="Andovar Reviews" href="https://www.g2.com/products/andovar/reviews?utm_campaign=testimonials_embed&amp;utm_medium=testimonials&amp;utm_source=Andovar">Andovar reviews sourced by G2</a>`
                }}
              />
            </div>
          </Col>
        </Row> */}
      </Container>
    </CaseStudyWrap>
  )
}

CaseStudy.propTypes = {
  layout: PropTypes.oneOf([1, 2])
}

CaseStudy.defaultProps = {
  layout: 1
}

export default CaseStudy
