import styled, { device } from "@styled";

export const LanguageFactsWrapper = styled.section`
  padding: 50px 0;

  ${device.medium} {
    padding: 80px 0;
  }

  ${device.large} {
    padding: 100px 0;
  }
`
export const LanguageFactsInner = styled.div`
  .introText p {
    font-size: 17px;
  }
`
export const LanguageTranslationList = styled.div``
export const LanguageTranslationItem = styled.div`
  color: #fff;
  &:not(:last-child) {
    margin-bottom: 20px;
  }
`
export const LanguageFactsList = styled.div``
export const LanguageFactsItem = styled.div`
  &:not(p) {
    margin-bottom: 20px;
    padding-left: 15px;
    position: relative;
    &::before {
      content: "";
      position: absolute;
      width: 6px;
      height: 6px;
      background: black;
      border-radius: 50px;
      left: 0;
      top: 12px;
    }
  }
`
